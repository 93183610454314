import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../main/components/button'
import { DefaultErrorBoundary } from '../../main/components/error-view'
import { Paragraph } from '../../main/components/typography'
import { AdTag, useInvestingChannelAds } from '../shared/components/Ads'
import * as tracking from '../shared/tracking'
import { Map, useMapContext } from './components/Map'
import { MapSelect } from './components/MapSelect'
import { MapTypeList } from './components/MapTypeList'
import { SearchInput, SearchResult, useSearch } from './components/Search'
import { Topbar } from './components/Topbar'
import { Expand } from './components/Topbar/Expand'
import { Share } from './components/Topbar/Share'
import { Zoom } from './components/Topbar/Zoom'
import { useMapData } from './hooks/use-map-data'
import { useMapType } from './hooks/use-map-type'
import { MapDataRoot, MapSubtypeId, PerfData } from './types'
import * as mapUtils from './utils'

const linkClassName = 'text-link hover:text-white'

export interface MapInitProps {
  initData?: MapDataRoot
  initialPerf?: PerfData
  cacheBuster: number
  generate?: boolean
}

function App(props: MapInitProps) {
  const { treemap } = useMapContext()
  const navigate = useNavigate()
  const { type, subtype } = useMapType()
  const mapData = useMapData(type.type, subtype.value, props.initData, props.initialPerf)
  const search = useSearch()
  // Make sure all ads are refreshed on type/subtype change (page navigation)
  const hasInvestingChannelAds = useInvestingChannelAds([type, subtype])

  /**
   * Set new subtype to be false on perf data load so we don’t display loading state when fetching
   * new perf data for the same type
   */
  const [isNewSubtype, setIsNewSubtype] = React.useState(false)
  React.useEffect(() => {
    if (!mapData.perfDataQuery.isFetching) {
      setIsNewSubtype(false)
    }
  }, [mapData.perfDataQuery.isFetching])

  const isLoadingInitialBaseData = !mapData.baseDataQuery.isPreviousData && mapData.baseDataQuery.isLoading
  const isLoadingInitialPerf = !mapData.perfDataQuery.isPreviousData && mapData.perfDataQuery.isLoading
  const isInitialLoading = isLoadingInitialBaseData || isLoadingInitialPerf
  const isFetchingNewPerf = isNewSubtype && !mapData.perfDataQuery.isLoading && mapData.perfDataQuery.isFetching

  return (
    <>
      <Topbar
        className="border-finviz-blue-gray bg-[#363a46] text-[#94a3b8] shadow"
        navContent={
          <>
            <h2 className="m-0 text-2xs uppercase opacity-50">View</h2>
            <Button as="a" href="/map.ashx" leftContent="chartTreemap" theme="transparent" className="text-gray-50">
              Map
            </Button>
            <Button as="a" href="/bubbles.ashx" leftContent="chartBubble" theme="transparent" className={linkClassName}>
              Bubbles
            </Button>
          </>
        }
        mapControls={
          <>
            <div className="flex px-2">
              <Expand className={linkClassName} data={mapData.data} />
              <Share className={linkClassName} mapType={type} subtype={subtype} />
            </div>
            <Zoom />
          </>
        }
      >
        <Paragraph size="small" className="leading-tight">
          {type.description}
        </Paragraph>
      </Topbar>
      <div className="flex h-full">
        <div className="map-sidebar min-h-145 w-50">
          <div className="mb-0.5 bg-[#404553] p-5 pt-3">
            <h2 className="m-0 text-2xs uppercase text-[#94a3b8] opacity-50">Map Filter</h2>
            <MapTypeList
              activeType={type.type}
              activeSubtype={subtype.value}
              isLoading={isInitialLoading}
              linkClassName={linkClassName}
            />
            <div className="mt-2">
              <MapSelect
                comboBox
                data-testid="map-subtype-select"
                aria-label="Data type"
                value={subtype.value}
                items={type.subtypes}
                isLoading={isFetchingNewPerf}
                onChange={(item) => {
                  const query = new URLSearchParams(window.location.search)
                  if (item.value === MapSubtypeId.DayPerf) {
                    query.delete('st')
                  } else {
                    query.set('st', item.value)
                  }

                  setIsNewSubtype(true)
                  const stringQuery = query.toString()
                  navigate({ pathname: window.location.pathname, search: stringQuery }, { replace: true })
                  tracking.trackPageView({ query: stringQuery })
                }}
              />
            </div>
          </div>

          <div className="bg-[#363a46]">
            <SearchInput
              value={search.value}
              onChange={search.onInputChange}
              className="cursor-text pl-3 text-gray-300"
              inputClass="bg-transparent placeholder-gray-400"
            />
          </div>
          <ul className="mt-0.5 divide-y divide-finviz-blue-gray bg-[#363a46] p-5 pt-4">
            {search.results.map((result) => (
              <SearchResult
                key={result.name}
                result={result}
                onClick={search.onItemClick}
                onMouseLeave={search.onResultMouseLeave}
                className="text-[#929cb3] hover:text-white"
              />
            ))}
          </ul>

          {hasInvestingChannelAds && (
            <div className="flex flex-col items-center pt-2">
              <AdTag name="IC_MapsLeftRail" />
              <AdTag name="IC_MapsLeftRail" position={2} />
            </div>
          )}
        </div>

        <ErrorBoundary resetKeys={[type.type, subtype.value]} FallbackComponent={DefaultErrorBoundary}>
          <div className="flex flex-1 flex-col overflow-hidden">
            <Map
              className="min-h-145 pl-4 pt-1"
              isFetchingPerf={isFetchingNewPerf}
              isLoadingData={!treemap && isInitialLoading}
              data={mapData.data}
              dataHash={mapData.perfDataQuery.data?.hash}
              type={type}
              subtype={subtype}
              onNodeClick={mapUtils.onNodeClick}
            />
            {hasInvestingChannelAds && <AdTag name="IC_TickerInContent" className="mx-auto my-3" />}
          </div>
        </ErrorBoundary>
      </div>

      <div id="modal"></div>
      <div className="clearfix"></div>
    </>
  )
}

export default App
